import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ServiceApiService } from "src/app/services/service-api.service";
import { data_table } from "../Edit/model";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
  providers: [DatePipe],
})
export class PaymentComponent implements OnInit {
  paymentform: FormGroup;
  paymentaddform: FormGroup;
  editpaymentform: FormGroup;
  data_tables: data_table[] = [];
  addtransactionModal: boolean = false;
  viewtransactionModal: boolean = false;
  sum_amts: number = 0;
  amt_paid: number = 0;
  balance_tenure: number = 0;
  balance_due : number = 0;
  submitted: boolean = false;
  editflag: boolean = false;
  tableflag: boolean = true;
  addbutton: boolean = false;
  loan_close_flag: boolean = false;
  myDate = new Date();
  previous_premium:number;
  isLoadingResults = true;
  isRateLimitReached = false;
  @Input("ID") Id: number;
  @Input("CUS_ID") CusId: number;
  @Input("ADDRESS") Address: string;
  @Input("MOB_NO") Mobileno: string;
  @Input("CUSTOMER_FIRST_NAME") cusname: string;

  constructor(
    private fb: FormBuilder,
    public api: ServiceApiService,
    private router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe
  ) {
    this.paymentform = this.fb.group({
      ID: 0,
      cus_id: 0,
      phoneno: [""],
      address: [""],
      Loan_date: [""],
      Duration: [""],
      Tenure: 0,
      Total_Amount: 0,
      Premium_Amount: 0,
      CUSTOMER_FIRST_NAME: [""],
      balanace_due: 0,
    });
    this.paymentaddform = this.fb.group({
      id: 0,
      loan_id: 0,
      Date: [new Date(), Validators.required],
      Premium_amt_recieved: 0,
      Collected_by: ["", Validators.required],
      Remarks: [""],
      loan_close_flag:false
    });
    this.editpaymentform = this.fb.group({
      id: 0,
      loan_id: 0,
      Date: ["", Validators.required],
      Premium_amt_recieved: 0,
      Collected_by: ["", Validators.required],
      Remarks: [""],
      loan_close_flag:false
    });
  }

  ngOnInit(): void {
    this.get_loan_details_id();
    this.addbutton = true;
  }
  ngOnChanges(changes: SimpleChanges) {
    // this.get_loan_details_id();
  }
  datesUpdated(e: any) {
    console.log("dateevent", e);
    this.paymentaddform.patchValue({"Date": e.endDate._d})
  }

  addtransaction() {
    this.addtransactionModal = true;
    this.paymentaddform.patchValue({
      Date: this.myDate,
      loan_id: this.paymentform.controls.ID.value,
      Premium_amt_recieved: this.paymentform.controls.Premium_Amount.value,
      Collected_by: null,
      Remarks: null,
    });

  }
  get f() {
    return this.paymentaddform.controls;
  }
  onFormSubmit() {
    if (this.paymentaddform.invalid) {
      alert("Fill the Fields");
      return;
    } else {
      this.addbutton = !this.addbutton;
     // console.log("paymentadd_res", paydata);
      if((this.paymentaddform.controls.Premium_amt_recieved.value - 0) > (this.balance_due - 0))
      {

        console.log('pamt',this.paymentaddform.controls.Premium_amt_recieved.value);
        console.log('bamt ',this.balance_due)
        this.toastr.error("Premium Amount Exceeds Balance Due");

      }
      else if(this.paymentaddform.controls.Premium_amt_recieved.value == this.paymentform.controls.balanace_due.value)
      {
        this.paymentaddform.patchValue({
          loan_close_flag:true
        });
        this.api.add_payment_details(this.paymentaddform.value).subscribe((data) => {
          this.get_res_cust(data);
        });
      }
      else{
        this.paymentaddform.patchValue({
          loan_close_flag:false
        });
        this.api.add_payment_details(this.paymentaddform.value).subscribe((data) => {
          this.get_res_cust(data);
        });

      }
    }
    this.paymentaddform.controls.Collected_by.setValue(null);
    this.paymentaddform.controls.Remarks.setValue(null);
    this.viewamtpaid();

  }
  updateForm() {
    this.poptable();
    this.submitted = true;

    if (this.editpaymentform.invalid) {
      return;
    } else {
     // console.log("paymentedit_res", paydata);

      if( (this.editpaymentform.controls.Premium_amt_recieved.value  - this.previous_premium) > this.paymentform.controls.balanace_due.value)
      {
        this.toastr.error("Premium Amount Exceeds Balance Due");

      }
      else if( (this.editpaymentform.controls.Premium_amt_recieved.value  - this.previous_premium) == this.paymentform.controls.balanace_due.value)
      {
        this.editpaymentform.patchValue({
          loan_close_flag:true
        });
        this.api.edit_payment_details(this.editpaymentform.value).subscribe((data) => {
          this.get_res_cust_updation(data);
        });
      }
      else{
        this.editpaymentform.patchValue({
          loan_close_flag:false
        });
        console.log("formvalues",this.editpaymentform.value);
        this.api.edit_payment_details(this.editpaymentform.value).subscribe((data) => {
          this.get_res_cust_updation(data);
        });
      }

    }
  }
  get_res_cust_updation(data: any) {
    if (data.success === true) {
      this.toastr.success(data.msg);
    } else {
      this.toastr.error(data.msg);
    }

    console.log('kim')

    this.hidetransaction();

    this.api
      .fetch_paymentdetails(this.paymentaddform.controls.loan_id.value)
      .subscribe((data) => {
        //console.log("data", data);
        this.generate_table(data.details);
      });
  }
  get_res_cust(data: any) {
    this.poptable();
    console.log("paymentadd_res", data);
    if (data.success === true) {
      this.toastr.success(data.msg);
    } else {
      this.toastr.error(data.msg);
    }
    this.hidetransaction();
  }
  clearform() {
    this.paymentaddform.reset();
  }
  hidetransaction() {
    this.addtransactionModal = false;
    this.viewtransactionModal = false;
    if (this.addbutton == false) {
      this.addbutton = !this.addbutton;
    }

    this.editflag = false;
    this.tableflag = true;
  }
  get_loan_details_id() {
    this.api.get_all_loan_details_by_id(this.Id).subscribe((data) => {
      this.make_details(data);
    });
  }

  make_details(data: any) {


    console.log("cusssdata", data);
    this.paymentform.patchValue({
      ID: this.Id,
      cus_id: this.CusId,
      CUSTOMER_FIRST_NAME: this.cusname,
      address: this.Address,
      phoneno: this.Mobileno,
      Loan_date: data.Loan_date,
      Duration: data.Duration,
      Tenure: data.Tenure,
      Total_Amount: data.Total_Amount,
      Premium_Amount: data.Premium_Amount,
    });

    this.viewamtpaid();

  }

  viewamtpaid() {
    this.api.fetch_paymentdetails(this.Id).subscribe((data) => {
      console.log("payyyyyyydata", data);
      this.generate_table(data.details);
    });
  }

  generate_table(data: any) {
    console.log('data_length',data.length);
    this.sum_amts = 0;
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        this.push_data();
        this.data_tables[i].si = i + 1;
        this.data_tables[i].date = data[i].Date;
        this.data_tables[i].amount = data[i].Premium_amt_recieved;
        this.data_tables[i].Collected_by = data[i].Collected_by;
        this.data_tables[i].remarks = data[i].Remarks;
        this.data_tables[i].id = data[i].id;
        this.sum_amts = (this.sum_amts-0) + (data[i].Premium_amt_recieved-0);
      }


      this.balance_tenure = this.paymentform.controls.Tenure.value - data.length;
      this.balance_due = (this.paymentform.controls.Total_Amount.value - 0) - (this.sum_amts-0)
      this.paymentform.patchValue({ balanace_due:  (this.paymentform.controls.Total_Amount.value - 0) - (this.sum_amts-0),
      });
    }
    else
    {
      this.balance_due = this.paymentform.controls.Total_Amount.value;
      this.paymentform.patchValue({ balanace_due: this.balance_due });
    }
  }

  click_amt() {
    if (this.paymentaddform.controls.Premium_amt_recieved.value == 0) {
      this.paymentaddform.controls.Premium_amt_recieved.setValue(null);
    }
  }
  blur_amt() {
    if (this.paymentaddform.controls.Premium_amt_recieved.value == null) {
      this.paymentaddform.controls.Premium_amt_recieved.setValue(0);
    }
  }
  checkamount() {
    if (this.paymentaddform.controls.Premium_amt_recieved.value < 0) {
      this.toastr.error("Amount Should greater than 0");
    }
  }

  viewtransaction() {
    // alert(this.data_tables.length);
    while (this.data_tables.length > 0) {
      this.data_tables.pop();
    }
    this.sum_amts = 0;
    this.viewtransactionModal = true;
    this.isLoadingResults=true;
    this.paymentaddform.patchValue({
      loan_id: this.paymentform.controls.ID.value,
    });
    console.log("loan id", this.paymentaddform.controls.loan_id.value);
    this.api
      .fetch_paymentdetails(this.paymentaddform.controls.loan_id.value)
      .subscribe((data) => {
        console.log("data", data);
        this.generate_table(data.details);
        this.isLoadingResults = false;

      });
  }

  push_data() {
    this.data_tables.push({
      date: "",
      amount: 0,
      Collected_by: "",
      remarks: "",
      si: 0,
      id: 0,
    });
  }
  editrow(
    id: number,
    date: string,
    amt: number,
    collected: string,
    remarks: string
  ) {
    console.log("editid", id);
    this.tableflag = false;
    this.editflag = true;
    this.editpaymentform.patchValue({
      id: id,
      loan_id: this.paymentform.controls.ID.value,
      Date: date,
      Premium_amt_recieved: amt,
      Collected_by: collected,
      Remarks: remarks,
    });
    this.previous_premium=this.editpaymentform.controls.Premium_amt_recieved.value;
  }
  deleterow(id: number) {
    console.log("deleteid", id);
    this.api.delete_payment_details(id,this.paymentaddform.controls.loan_id.value).subscribe((data) => {
      console.log("dataaaa", data);
      if (data.success === true) {
        this.toastr.success(data.msg);
        this.viewtransaction();
      } else {
        this.toastr.error(data.msg);
      }
    });
  }
  back() {
    this.editflag = false;
    this.tableflag = true;
  }
  poptable() {
    while (this.data_tables.length > 0) {
      this.data_tables.pop();
    }
  }
}

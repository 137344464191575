import { Injectable } from '@angular/core';
import { isNull } from "@angular/compiler/src/output/output_ast";
import { isEmpty } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  logoutUser() :void {
    localStorage.setItem('isLoggedIn','false');
      localStorage.removeItem('token');
  }

}

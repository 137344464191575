import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ServiceApiService } from '../../../services/service-api.service';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css'],
})
export class EditCustomerComponent implements OnChanges {
  Customerform: FormGroup;

  @Input('custid') customer_id: number;

  @Output() tablechange = new EventEmitter<any>();
  submitted:boolean=false;
  types:string[]=['PAN No','PASSPORT','VOTER ID','DRIVING LICENCE','AADHAAR'];
  constructor(private fb: FormBuilder, public api: ServiceApiService,private toastr: ToastrService) {}

  ngOnInit(): void {
    this.Customerform = this.fb.group({
      CUSTOMER_FIRST_NAME: ['', [Validators.required, Validators.min(1)]],
      CUSTOMER_LAST_NAME: [''],
      ADDRESS: ['', Validators.required],
      MOB_NO: ['', [ Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10)]],
      CONTACT_NO: [''],
      EMAIL: ['',[Validators.required, Validators.email]],
      KYC_TYPE: [''],
      KYC_NO: [''],
      CITY: ['',Validators.required],
      id: 0,
    });
    console.log(this.customer_id);
    this.Customerform.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.get_customer_by_id();
  }
  get_customer_by_id() {
    this.api.get_customer_by_id(this.customer_id).subscribe((data) => {
      console.log('cusssdata',data);
      this.Customerform.patchValue({
        CUSTOMER_FIRST_NAME: data.CUSTOMER_FIRST_NAME,
        CUSTOMER_LAST_NAME: data.CUSTOMER_LAST_NAME,
        ADDRESS: data.ADDRESS,
        id: data.id,
        CITY: data.CITY,
        CONTACT_NO: data.CONTACT_NO,
        MOB_NO: data. MOB_NO,
        EMAIL: data.EMAIL,
        KYC_TYPE: data. KYC_TYPE,
        KYC_NO: data. KYC_NO,
      });
    });
  }
  get f() {
    return this.Customerform.controls;
  }
  onFormSubmit(custdata: any) {
    this.submitted = true;
    if (this.Customerform.invalid) {
      return;
    } else {
    this.api.update_customer(custdata).subscribe((data) => {
      this.get_res_cust(data);
    });
  }
  }
  Delete(cus:any) {
    this.api.delete_customer(this.customer_id).subscribe((data) => {
      console.log('customerdelete', data);
      if (data.sucess === true) {
        this.toastr.success(data.msg);
        this.api.showModal = 'none';
        this.tablechange.emit(data.sucess);
      }
      else{
        this.toastr.error("Customer Taken");
      }
    });
  }
  get_res_cust(data: any) {
    // console.log('data', data);
    if (data.success === true) {
      this.toastr.success(data.msg);
      this.api.showModal = 'none';
      this.tablechange.emit(data.success);
    }
    else{
      this.toastr.error(data.msg);
    }
  }
}

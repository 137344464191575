import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceApiService } from '../../services/service-api.service';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  public report1:any;

  constructor(private api:ServiceApiService,private router: Router) {}

  ngOnInit(): void {
  }
  dailywise() {
    this.report1=0;
    localStorage.setItem('report_id',this.report1);
    this.router.navigate(['/home/reports-detail']);
  }
  customerwise()
  {
    this.report1=1;
    localStorage.setItem('report_id',this.report1);
    this.router.navigate(['/home/reports-detail']);
  }
  summary()
  {
    this.report1=2;
    localStorage.setItem('report_id',this.report1);
    this.router.navigate(['/home/reports-detail']);
  }

}

import { Component,OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppService } from './services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pro-dashboard-angular';
  user_role:string;
  date: Date = new Date();
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true,
    minDate: new Date()
  }
  constructor(private appService: AppService, private ps:NgxPermissionsService , private rs : Router) {}
  ngOnInit()
  {
    this.user_role= localStorage.getItem('token');

    if(this.user_role=='Admin')
    {
      this.ps.loadPermissions([this.user_role]);
      this.rs.navigate(['/home/dash'])
    }
    else
    {
      this.ps.loadPermissions(['Guest']);
      this.rs.navigate(['/login'])
    }
    console.log('token',this.user_role);
  }
  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }
}

import { Component, OnInit } from '@angular/core';
import { ServiceApiService } from '../../services/service-api.service';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public columns_customer: Columns[];
  customersno:number=0;
  loanno:number=0;
  public data_customer = [];
  flag: number = 0;
  _addmodal = 0;
  CusId: any;
  _modal = 0;

  public configuration: Config;

  constructor(public api: ServiceApiService,private router:Router) { }

  ngOnInit() {

    this.api.showModal  = 'none';
    this.api.displayModal  = 'none';
    this.customers_list();
    this. loan_list();
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    // ... etc.
    this.columns_customer = [
      { key: 'CUSTOMER_FIRST_NAME', title: 'First Name' },
      { key: 'CUSTOMER_LAST_NAME', title: 'last Name' },
      { key: 'ADDRESS', title: 'Address' },
      { key: 'MOB_NO', title: 'Contact No.' },
    ];
  }
  customers_list() {
    this.api.get_all_customer().subscribe((data) => {
      this.data_customer = data;
      console.log(data);
      this.customersno=data.length;
    });
  }
  loan_list()
  {
    this.api.get_all_loanlist().subscribe((data) => {
      console.log('response table',data);
      this.loanno=data.length;
     });
  }

  cust_view() {
    this.flag = 1;
  }
  loan_view()
  {
    this.router.navigate(['home/view-order']);
  }

  /*****************GET MODAL************************** */
  addCustomer() {
    this.api.displayModal  = 'block';
    this._addmodal = 1;
  }
  onEvent_customer(event) {
    console.log('clickevent',event);
     if(event.event==='onClick')
    {
      this.api.showModal = 'block';
      this.CusId = event.value.row.id;
      this._modal = 1;
    }
  }

  hide() {
    this.api.showModal = 'none';
    this.api.displayModal  = 'none';
  }
  customer_table(e: any) {
    console.log('addcustomerevent',e);

    if(e == true)
      {
        this.customers_list();
      }

  }
}

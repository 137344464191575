import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { ServiceApiService } from '../../services/service-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  user: User;
  returnUrl: string;
  constructor(
    private fb: FormBuilder,
    public api: ServiceApiService,
    private router: Router,
    private authService:AuthService,
    private toastr: ToastrService,
    private ps:NgxPermissionsService
  ) {}

  ngOnInit(): void {


    this.loginForm = this.fb.group({
      user_name: ['', [Validators.required]],
      password: ['', Validators.required],
    });
    console.log('intited')
  }
  onFormSubmit(login_dets: any) {
    this.submitted = true;
    this.api.login(login_dets).subscribe((data) => {
      console.log('response',data);
      this.get_res_login(data);
    });
  }

  get_res_login(data: any) {
    console.log('data', data);
    if (data['success'] === true) {
      let loguser: User = {
        role: data["role"],
      };
      localStorage.setItem('isLoggedIn', "true");
      localStorage.setItem('token', data["role"]);
      this.ps.loadPermissions([data.role]);
      this.router.navigate(['home']);
    }
    else{
      this.toastr.error("Invalid Credentials");
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ServiceApiService } from "../../../services/service-api.service";
import { data_table } from "./model";

@Component({
  selector: "app-edit-jobsheet",
  templateUrl: "./edit-jobsheet.component.html",
  styleUrls: ["./edit-jobsheet.component.css"],
})
export class EditJobsheetComponent implements OnChanges {
  Loanform: FormGroup;
  customers: any;
  displayModal: boolean = false;
  _modal: number = 0;
  count: number = 0;
  editmaster: boolean = true;

  submitted: boolean = false;
  @Input("ID") Id: number;
  @Input("CUS_ID") CusId: number;
  @Input("ADDRESS") Address: string;
  @Input("MOB_NO") Mobileno: string;
  @Input("KYC_NO") Kycno: string;
  @Input("KYC_TYPE") Kyctype: string;
  @Input("CUSTOMER_FIRST_NAME") Cusname: string;
  @Output() tablechange = new EventEmitter<any>();
  constructor(
    private fb: FormBuilder,
    private api: ServiceApiService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.Loanform = this.fb.group({
      id: 0,
      cus_id: 0,
      phoneno: [""],
      address: [""],
      Kyc_type: [""],
      Kyc_no: [""],
      Bailiff_Name: ["", Validators.required],
      Bailiff_Address: ["", Validators.required],
      Bailiff_Kyc_type: ["", Validators.required],
      Bailiff_Kyc_no: ["", Validators.required],
      Loan_date: ["", Validators.required],
      Duration: ["", Validators.required],
      Tenure: ["", Validators.required],
      Principle_Amount : ['', Validators.required],
      Interest_Amount : ['', Validators.required],
      Total_Amount : ['', Validators.required],
      Premium_Amount : ['', Validators.required],
      CUSTOMER_FIRST_NAME: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.get_customers();
    // this.viewtransaction_on_init();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.get_loan_details_id();
  }
  get_loan_details_id() {
    this.api.get_all_loan_details_by_id(this.Id).subscribe((data) => {
      console.log("cusssdata", data);
      this.Loanform.patchValue({
        id: this.Id,
        cus_id: this.CusId,
        CUSTOMER_FIRST_NAME: this.Cusname,
        address: this.Address,
        phoneno: this.Mobileno,
        Kyc_type: this.Kyctype,
        Kyc_no: this.Kycno,
        Bailiff_Name: data.Bailiff_Name,
        Bailiff_Address: data.Bailiff_Address,
        Bailiff_Kyc_type: data.Bailiff_Kyc_type,
        Bailiff_Kyc_no: data.Bailiff_Kyc_no,
        Loan_date: data.Loan_date,
        Duration: data.Duration,
        Tenure: data.Tenure,
        Principle_Amount: data.Principle_Amount,
        Interest_Amount: data.Interest_Amount,
        Total_Amount: data.Total_Amount,
        Premium_Amount: data.Premium_Amount,
      });
    });
  }

  Selected1($event) {
    if ($event.target.checked === true) {

      this.editmaster=true;
    }
  }
  Selected2($event) {
    if ($event.target.checked === true) {

      this.editmaster=false;
    }
  }
  editmode() {
    this.api.edit_mode = false;
    this.router.navigate(["/nav/viewjobsheets"]);
  }
  /*****************GET CUSTOMERS************************** */
  search_customer(e: any) {
    console.log(e);
    this.Loanform.patchValue({
      CUSTOMER_NAME: e.CUSTOMER_NAME,
      cus_id: e.id,
      address: e.ADDRESS,
      phoneno: e.MOB_NO,
      Kyc_type: e.KYC_TYPE,
      Kyc_no: e.KYC_NO,
    });
    this.Loanform.get("Kyc_type").patchValue(e.KYC_TYPE);
  }
  custom_cus_selected(e: any) {
    this.toastr.error("wrong selection");
  }
  get_customers() {
    this.api.get_all_customer().subscribe((data) => {
      this.customers = data;
      console.log("customers", data);
    });
  }
  loadcus(e: any) {
    console.log("event", e);
    if (e == true) {
      this.get_customers();
    }
  }

  /*****************GET MODAL************************** */
  addCustomer() {
    this.count = 1;
    this._modal = 1;
  }
  hide() {
    this.displayModal = false;
  }


  get f() {
    return this.Loanform.controls;
  }
  /*****************Submit data************************* */
  update(orderdata: any) {
    console.log("responsedata", orderdata);
    this.submitted = true;
    this.api.update_loansheet(orderdata).subscribe((data) => {
      console.log("res", data);
      this.get_res_order(data);
    });
  }
  get_res_order(data: any) {
    console.log("data......", data);
    if (data.success === true) {
      this.toastr.success(data.msg);
      this.tablechange.emit(data.success);
      this.api.edit_mode = false;
      this.router.navigate(["/home"]);
    } else {
      this.toastr.error(data.msg);
    }
  }
   /*****************autofetch amounts************************** */

   blur_principleamt() {
    if ( this.Loanform.controls.Principle_Amount.value == null) {
      this.Loanform.controls.Principle_Amount.setValue(0);
    }
  }
  click_principleamt() {
    if ( this.Loanform.controls.Principle_Amount.value == 0) {
      this.Loanform.controls.Principle_Amount.setValue(null);
    }
  }
  blur_interestamt() {
    if ( this.Loanform.controls.Interest_Amount.value == null) {
      this.Loanform.controls.Interest_Amount.setValue(0);
    }
  }
  click_interestamt() {
    if ( this.Loanform.controls.Interest_Amount.value == 0) {
      this.Loanform.controls.Interest_Amount.setValue(null);
    }
  }
  blur_premiumamt() {
    if (this.Loanform.controls.Premium_Amount.value == null) {
      this.Loanform.controls.Premium_Amount.setValue(0);
    }
  }
  click_premiumamt() {
    if (this.Loanform.controls.Premium_Amount.value == 0) {
      this.Loanform.controls.Premium_Amount.setValue(null);
    }
  }
  changetotalamt() {
    if ( this.Loanform.controls.Principle_Amount.value > 0) {
      this.Loanform.controls.Total_Amount.setValue(
        ( this.Loanform.controls.Principle_Amount.value -0)
         + ( this.Loanform.controls.Interest_Amount.value - 0)
      );
    }
    this.changepremiumamount();
  }
  checktotalamount() {
    if (this.Loanform.controls.Premium_Amount.value > this.Loanform.controls.Total_Amount.value) {
      this.toastr.error("Premium Amount > Total Amount not allowed");
    }
  }
  changepremiumamount()
  {
    if(this.Loanform.controls.Tenure.value != null)
    {
      this.Loanform.controls.Premium_Amount.setValue((this.Loanform.controls.Total_Amount.value - 0) / (this.Loanform.controls.Tenure.value - 0));
      var amt=Math.round(this.Loanform.controls.Premium_Amount.value *100)/100;
      this.Loanform.controls.Premium_Amount.setValue(amt);
      console.log('tenure',this.Loanform.controls.Tenure.value);
      console.log('amtttt',this.Loanform.controls.Total_Amount.value);
    }

  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { JobsheetComponent } from './pages/Order/New/jobsheet.component';
import { ViewjobComponent } from './pages/Order/View/viewjob.component';
import { EditJobsheetComponent } from './pages/Order/Edit/edit-jobsheet.component';
import { ReportsComponent } from './pages/Reports/reports.component';
import { DetailReportsComponent } from './pages/Reports/Detail/enquiry-reports.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { PaymentComponent } from './pages/Order/payment/payment.component';
const routes: Routes = [
  {path: '',   redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'home', component: HomeComponent,
  children: [
      {path: '',   redirectTo: 'dash', pathMatch: 'full'},
      {path: 'dash',component: DashboardComponent},
      {path: 'new-order', component: JobsheetComponent},
      {path: 'view-order', component: ViewjobComponent},
      {path: 'editjobsheet', component: EditJobsheetComponent},
      {path: 'payment', component: PaymentComponent},
      {path: 'reports', component: ReportsComponent},
      {path: 'reports-detail', component: DetailReportsComponent},
    ],
    canActivate: [NgxPermissionsGuard],
  data: {
    permissions: {
      only: 'Admin'
    }
  }
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

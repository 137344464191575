import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ExportToCsv } from "export-to-csv";
import { ExcelService } from '../../../services/excel.service';
import { Columns, DefaultConfig } from "ngx-easy-table";
import { ToastrService } from "ngx-toastr";
import { Config } from "protractor";
import { ServiceApiService } from "../../../services/service-api.service";
import { customertype, loantype, master, item } from "./model";
import { TableUtil } from "./tableUtil";
@Component({
  selector: "app-enquiry-reports",
  templateUrl: "./enquiry-reports.component.html",
  styleUrls: ["./enquiry-reports.component.css"],
})
export class DetailReportsComponent implements OnInit {
  reportsform: FormGroup;
  currentDate = new Date();
  mydata: loantype[] = [];
  customertype_mod: customertype[] = [];
  displaycustomer: boolean = false;
  checkboxsstatus: boolean = true;
  enq: boolean = false;
  _is_load: number = 0;
  autocompletebox: boolean = false;
  customerrfocus: boolean = false;
  sum: number = 0;
  i:number=0;
  j:number=0;


  principle_amt_sum :number = 0;
  interest_sum : number = 0;
  recieved_sum : number = 0;
  due_sum : number = 0;
  _is_disabled : boolean = false;


  csv: boolean = false;
  loanmaster_detail: item[]=[];
  public configuration: Config;
  public columns_dailywise: Columns[];
  public columns_custwise: Columns[];
  public report_flag: any;
  constructor(
    private fb: FormBuilder,
    private api: ServiceApiService,
    private router: Router,public es: ExcelService,
    private toastr: ToastrService
  ) {
    this.reportsform = this.fb.group({
      FROM_DATE: ["", Validators.required],
      TO_DATE: ["", Validators.required],
      ID: [""],
      loan_id: [""],
      Date: [""],
      CUSTOMER_ID: 0,
      CUSTOMER_NAME: [""],
      CUSTOMER_PHONE: [""],
      ADDRESS: [""],
      Collected_by: [""],
      MOB_NO: [""],
      Premium_amt_recieved: [""],
      Remarks: [""],
    });
  }
  ngOnInit(): void {
    this.report_flag = localStorage.getItem("report_id");
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.configuration.isLoading = false;
    this.columns_dailywise = [
      // { key: "ID", title: "Id", width: "250px" },
      { key: "loan_id", title: "Loan Id", width: "250px" },
      { key: "Date", title: "Date", width: "250px" },
      { key: "CUSTOMER_FIRST_NAME", title: "Customer", width: "250px" },
      { key: "ADDRESS", title: "Address", width: "250px" },
      { key: "MOB_NO", title: "Phone No.", width: "250px" },
      {key: "Premium_amt_recieved",title: "Premium Amount Received By", width: "250px"},
      { key: "Collected_by", title: "Collected By", width: "250px" },
      { key: "Remarks", title: "Remarks", width: "250px" },
    ];
    this.columns_custwise = [
      // { key: "ID", title: "Id", width: "250px" },
      { key: "loan_id", title: "Loan Id", width: "250px" },
      { key: "Date", title: "Date", width: "250px" },
      {key: "Premium_amt_recieved",title: "Premium Amount Received By",width: "250px"},
      { key: "Collected_by", title: "Collected By", width: "250px" },
      { key: "Remarks", title: "Remarks", width: "250px" },
    ];
    console.table("reports", this.reportsform.value);
    this.get_customers();
  }


  exportToexcel()
  {
    TableUtil.exportToExcel("full_e");
  }
  /*****************GET CUSTOMERS************************* */
  search_customer(e: any) {
    console.log("eventttt", e);
    console.log("eventttt", e.ID);
    this.reportsform.patchValue({
      CUSTOMER_ID: e.ID,
      CUSTOMER_NAME: e.CUSTOMER_NAME,
      CUSTOMER_PHONE: e.CUSTOMER_PHONE,
    });
  }
  custom_customer_selected(e: any) {
    this.toastr.error("wrong selection");
  }
  get_customers() {
    this.api.get_all_customer().subscribe((data) => {
      console.log("custdatataaat", data);
      this.assign_customer_list(data);
    });
  }
  assign_customer_list(data) {
    var work = 0;
    while (work < this.customertype_mod.length) {
      work = work + 1;
      this.customertype_mod.pop();
    }
    this.customertype_mod.push({
      ID: 0,
      CUSTOMER_NAME: "No selection",
      CUSTOMER_PHONE: "",
    });
    for (var i = 0; i < data.length; i++) {
      this.customertype_mod.push({
        ID: data[i].id,
        CUSTOMER_NAME: data[i].CUSTOMER_FIRST_NAME,
        CUSTOMER_PHONE: data[i].MOB_NO,
      });
    }
  }
  customerfocus() {
    this.customerrfocus = true;
  }
  searchbutton() {
    if (this.report_flag == 0) {
      if (this.reportsform.controls.FROM_DATE.value == "") {
        this.toastr.error("Select Date");
      } else {
        this.post_dailyreport();
      }
    } else if (this.report_flag == 1) {
      this.post_custwise_report();
    } else if (this.report_flag == 2) {

      if (this.reportsform.controls.FROM_DATE.value == "" || this.reportsform.controls.TO_DATE.value == "") {
        this.toastr.error("Select Proper Dates");
      } else {
        this.summary_report();
      }
      this._is_disabled = true;
    }
    this.csv = true;
  }
  post_dailyreport() {
    this.api
      .get_all_dialyreports(this.reportsform.controls.FROM_DATE.value)
      .subscribe((data: any) => {
        console.log("response..", data);
        this.make_data(data);
      });
  }
  make_data(data: any) {
    var ord_ = 0;
    while (ord_ <= this.mydata.length) {
      ord_ = ord_ + 1;
      this.mydata.pop();
    }
    this.sum = 0;
    for (var i = 0; i < data.length; i++) {
      this.mydata.push({
        ID: data[i].id,
        loan_id: data[i].loan_id,
        Date: data[i].Date,
        CUSTOMER_FIRST_NAME: data[i].CUSTOMER_FIRST_NAME,
        ADDRESS: data[i].ADDRESS,
        Collected_by: data[i].Collected_by,
        MOB_NO: data[i].MOB_NO,
        Premium_amt_recieved: data[i].Premium_amt_recieved,
        Remarks: data[i].Remarks,
      });
      this.sum = this.sum + data[i].Premium_amt_recieved;
    }

    this.mydata.push({
      ID: 0,
      loan_id: 0,
      Date: "",
      CUSTOMER_FIRST_NAME: "Total Amount",
      ADDRESS: "",
      Collected_by: "",
      MOB_NO: 0,
      Premium_amt_recieved: this.sum,
      Remarks: "",
    });

    this.enq = true;
  }
  post_custwise_report() {
    console.log(
      "responseREPORTSSSS..",
      this.reportsform.controls.CUSTOMER_ID.value
    );
    this.api
      .get_all_custwise_reports(this.reportsform.controls.CUSTOMER_ID.value)
      .subscribe((data: any) => {
        console.log("response..", data);
        this.make_cust_data(data);
      });
  }
  make_cust_data(data: any) {
    var ord_ = 0;

    while (this.mydata.length >= ord_) {
      this.mydata.pop();
      ord_ = ord_ + 1;
    }
    this.sum = 0;
    for (var i = 0; i < data.length; i++) {
      this.mydata.push({
        ID: data[i].id,
        loan_id: data[i].loan_id,
        Date: data[i].Date,
        CUSTOMER_FIRST_NAME: "",
        ADDRESS: "",
        Collected_by: data[i].Collected_by,
        MOB_NO: 0,
        Premium_amt_recieved: data[i].Premium_amt_recieved,
        Remarks: data[i].Remarks,
      });
      this.sum = this.sum + data[i].Premium_amt_recieved;
    }
    console.log("datalength", data.length);

    this.mydata.push({
      ID: 0,
      loan_id: 0,
      Date: "",
      CUSTOMER_FIRST_NAME: "Total Amount",
      ADDRESS: "",
      Collected_by: "",
      MOB_NO: 0,
      Premium_amt_recieved: this.sum,
      Remarks: "",
    });

    this.enq = true;
  }
  exportToCSV(): void {
    var ord_ = 0;

    while (this.mydata.length >= ord_) {
      this.mydata.pop();
      ord_ = ord_ + 1;
    }
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.mydata);
  }

  summary_report() {
    this.api
      .get_all_summary_reports(this.reportsform.controls.FROM_DATE.value,this.reportsform.controls.TO_DATE.value)
      .subscribe((data: any) => {
        console.log("Summary_response..", data);
          this.make_masteritem_data(data,data.length);
      });
  }
  make_masteritem_data(data:any,len:number) {
    this.i=0;
    this.j=0;
    var temp_sum_recieved=0;
    var temp_rem_due=0
    this.principle_amt_sum = 0;
    this.interest_sum = 0;
    this.recieved_sum = 0;
    this.due_sum = 0;

    for(this.i;this.i<len;this.i++)
    {
      temp_sum_recieved = 0;

      for(this.j;this.j<data[this.i].Loan_details.length;this.j++)
      {
        temp_sum_recieved = (temp_sum_recieved-0) + (data[this.i].Loan_details[this.j].Premium_amt_recieved-0);

      }

      temp_rem_due = data[this.i].Total_Amount - temp_sum_recieved


      this.loanmaster_detail.push({ si:this.i+1,id:data[this.i].id,loan_date:data[this.i].Loan_date,cus_name:data[this.i].customer_list.CUSTOMER_FIRST_NAME,cus_address:data[this.i].customer_list.ADDRESS,premium:data[this.i].Premium_Amount,
      cus_mob:data[this.i].customer_list.MOB_NO,principle_amt:data[this.i].Principle_Amount,interest_amt:data[this.i].Interest_Amount,total_amt:data[this.i].Total_Amount,tenure:data[this.i].Tenure,recieved_amt:temp_sum_recieved,due_amt:temp_rem_due,is_loan_closed:false});


      this.principle_amt_sum = this.principle_amt_sum + data[this.i].Principle_Amount;
      this.interest_sum = this.interest_sum + data[this.i].Interest_Amount;
      this.recieved_sum = this.recieved_sum + temp_sum_recieved;
      this.due_sum = this.due_sum + temp_rem_due;
    }





 // console.log('fd',this.loanmaster_detail.items);

  }

}

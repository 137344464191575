import { Component, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ServiceApiService } from '../../../services/service-api.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css'],
})
export class AddCustomerComponent implements OnInit {
  Customerform: FormGroup;
  @Output() tablechange = new EventEmitter<any>();
  @Output() cusloadchange = new EventEmitter<any>();
  submitted: boolean = false;
  types:string[]=['PAN No','PASSPORT','VOTER ID','DRIVING LICENCE','AADHAAR'];
  constructor(
    private fb: FormBuilder,
    private api: ServiceApiService,
    private toastr: ToastrService
  ) {}


  ngOnInit(): void {
    this.Customerform = this.fb.group({
      CUSTOMER_FIRST_NAME: ['', [Validators.required, Validators.min(1)]],
      CUSTOMER_LAST_NAME: [''],
      ADDRESS: ['', Validators.required],
      MOB_NO: ['', [ Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(12)]],
      CONTACT_NO: [''],
      EMAIL: ['',[Validators.required, Validators.email]],
      KYC_TYPE: [''],
      KYC_NO: [''],
      CITY: ['',Validators.required],
    });
    this.Customerform.patchValue({
      KYC_TYPE: this.types[4],
    });
  }

  get f() {
    return this.Customerform.controls;
  }
  onFormSubmit(custdata: any) {
    this.submitted = true;
   // console.log('customerdata',custdata);
    if (this.Customerform.invalid) {
      return;
    } else {
      console.log('customerdata',custdata);
      this.api.add_customer(custdata).subscribe((data) => {
        this.get_res_cust(data);
      });
    }
  }
  get_res_cust(data: any) {
    console.log('cus_res', data);
    if (data.success === true) {
      this.toastr.success(data.msg);
    } else {
      this.toastr.error(data.msg);
    }
    this.tablechange.emit(data.success);
    this.cusloadchange.emit(data.success);
    this.Customerform.reset();
  }
}

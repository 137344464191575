import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import{customer} from '../models/customer';
import { promise } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root',
})
export class ServiceApiService {
  private localUrl = 'http://192.168.0.10:4231/';



  // http://cfs.asajaitsolutions.cox


  private getUrls =   'https://cfs.asajaitsolutions.com/chitty-api/';
  private getUrlss =  'https://cfs.asajaitsolutions.com/chitty-api/get/';
  private loginUrl =  'https://cfs.asajaitsolutions.com/chitty-api/admin/';
  private postUrl =   'https://cfs.asajaitsolutions.com/chitty-api/post/';

  //    private getUrls =   'http://127.0.0.1:4404/chitty-api/';
  //    private getUrlss =  'http://127.0.0.1:4404/chitty-api/get/';
  //  private loginUrl =  'http://127.0.0.1:4404/chitty-api/admin/';
  //  private postUrl =   'http://127.0.0.1:4404/chitty-api/post/';

  //public token:any;
  public navtoken:boolean=false;
  public showModal:string;
  public displayModal:string;
  public edit_mode:boolean=false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private authService:AuthService) {
    this.showModal = 'none';
    this.displayModal = 'none';
  }
  logout() :void {
    localStorage.setItem('isLoggedIn','false');
    localStorage.removeItem('token');
  }
  login(model: any) {
    let body = JSON.stringify(model);
    console.log('body', body);
    return this.http
      .post(this.loginUrl + 'login', body, this.httpOptions)
      .pipe(catchError(this.handleError<any>('login', body)));
  }

  /*****************Add Customer************************** */
  add_customer(model: any) {
    let body = JSON.stringify(model);
    console.log('body', body);
    return this.http
      .post(this.postUrl + 'add-new-customer', body, this.httpOptions)
      .pipe(catchError(this.handleError<any>('add-new-customer', body)));
  }
   /*****************GET CUSTOMERS************************** */
   get_all_customer(): Observable<any> {
    return this.http
      .get(this.getUrls + '_all_customers', this.httpOptions)
      .pipe(catchError(this.handleError<any>('_all_customers')));
  }
/*****************Get Customer************************** */
 get_customer_by_id(customer_id: number): Observable<any> {
    const url = `${this.getUrls + 'customer_by_id'}/${customer_id}`;
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(catchError(this.handleError<any>('customer_by_id')));
  }
  /*****************Edit Customer************************** */
  update_customer(model: any) {
    let body = JSON.stringify(model);
    console.log('body', body);
    return this.http
      .post(this.postUrl + 'update-customer', body, this.httpOptions)
      .pipe(catchError(this.handleError<any>('update-customer', body)));
  }
  delete_customer(customer_id: number): Observable<any> {
    const url = `${this.getUrls + 'delete_customer_by_id'}/${customer_id}`;
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(catchError(this.handleError<any>('delete_customer_by_id')));
  }
 /*****************New Job loan************************** */
 createloan(model: any) {
  let body = JSON.stringify(model);
  console.log('body', body);
  return this.http
    .post(this.postUrl + 'add-new-loan', body, this.httpOptions)
    .pipe(catchError(this.handleError<any>('add-new-loan', body)));
}
get_all_loanlist(): Observable<any> {
  return this.http
    .get(this.getUrlss + '_all_loans_master', this.httpOptions)
    .pipe(catchError(this.handleError<any>('_all_loans_master')));
}
get_all_loan_details_by_id(loan_id: number): Observable<any> {
  const url = `${this.getUrls + '_all_loans_master_by_id'}/${loan_id}`;
  return this.http
    .get<any>(url, this.httpOptions)
    .pipe(catchError(this.handleError<any>('_all_loans_master_by_id')));
}
update_loansheet(model: any) {
  let body = JSON.stringify(model);
  console.log('body', body);
  return this.http
    .post(this.postUrl + 'update-loan-master', body, this.httpOptions)
    .pipe(catchError(this.handleError<any>('update-loan-master', body)));
}
 /*****************New Job loan************************** */
 add_payment_details(model: any) {
  let body = JSON.stringify(model);
  console.log('body', body);
  return this.http
    .post(this.postUrl + 'add-new-premium', body, this.httpOptions)
    .pipe(catchError(this.handleError<any>('add-new-premium', body)));
}
fetch_paymentdetails(loan_id: number): Observable<any> {
  const url = `${this.getUrls + 'loan_details_by_id'}/${loan_id}`;
  return this.http.get<any>(url, this.httpOptions)
    .pipe(catchError(this.handleError<any>('loan_details_by_id')));
}
edit_payment_details(model: any) {
  let body = JSON.stringify(model);
  console.log('body', body);
  return this.http
    .post(this.postUrl + 'update-premium', body, this.httpOptions)
    .pipe(catchError(this.handleError<any>('update-premium', body)));
}

delete_payment_details(pay_id: number,loan_id:number): Observable<any> {

  const url = `${this.postUrl + 'delete-premium'}/${pay_id}/${loan_id}`;
  return this.http
  .get(url,this.httpOptions)
    .pipe(catchError(this.handleError<any>('delete-premium')));

}
get_all_dialyreports(date: string): Observable<any> {
  const url = `${this.postUrl + 'report-daily-collection'}/${date}`;
  return this.http
  .get(url,this.httpOptions)
    .pipe(catchError(this.handleError<any>('report-daily-collection')));
}
get_all_custwise_reports(cusid:number): Observable<any> {
  const url = `${this.postUrl + 'report-customer-wise'}/${cusid}`;
  return this.http
  .get(url,this.httpOptions)
    .pipe(catchError(this.handleError<any>('report-daily-collection')));
}
get_all_summary_reports(date1:string,date2:string): Observable<any> {
 let post_data = {"frd":date1,"tod":date2}
   let body = JSON.stringify(post_data);

   console.log('body', body);
   return this.http
     .post(this.postUrl + 'report-consolidated', body, this.httpOptions)
     .pipe(catchError(this.handleError<any>('get_all_summary_reports', body)));


  // const url = `${this.postUrl + 'report-consolidated'}/${date1}/${date2}`;
  // return this.http
  // .get(url,this.httpOptions)
  //   .pipe(catchError(this.handleError<any>('report-consolidated')));
}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServiceApiService } from '../../../services/service-api.service';

@Component({
  selector: 'app-jobsheet',
  templateUrl: './jobsheet.component.html',
  styleUrls: ['./jobsheet.component.css'],
})
export class JobsheetComponent implements OnInit {
  Loanform: FormGroup;
  customers: any;
  displayModal: boolean = false;
  _modal: number = 0;
  submitted:boolean=false;
  constructor(
    private fb: FormBuilder,
    public api: ServiceApiService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.Loanform = this.fb.group({
      cus_id: 0,
      phoneno: [''],
      address: [''],
      Kyc_type : [''],
      Kyc_no : [''],
      Bailiff_Name:['', Validators.required],
      Bailiff_Address:['', Validators.required],
      Bailiff_Kyc_type:['', Validators.required],
      Bailiff_Kyc_no:['', Validators.required],
      Loan_date: ['', Validators.required],
      Duration: ['', Validators.required],
      Tenure: [0, Validators.required],
      Principle_Amount : [0, Validators.required],
      Interest_Amount : [0, Validators.required],
      Total_Amount : [0, Validators.required],
      Premium_Amount : [0, Validators.required],
      CUSTOMER_FIRST_NAME : ['', Validators.required],
    });
    this.get_customers();

  }
  /*****************GET CUSTOMERS************************** */
  search_customer(e: any) {
    console.log('dattttttt',e);

    this.Loanform.patchValue({
      CUSTOMER_NAME: e.CUSTOMER_NAME,
      cus_id: e.id,
      address: e.ADDRESS,
      phoneno: e.MOB_NO,
      Kyc_type:e.KYC_TYPE,
      Kyc_no:e.KYC_NO
    });
    this.Loanform.get("Kyc_type").patchValue(e.KYC_TYPE);
  }


  custom_cus_selected(e: any) {
    this.toastr.error("wrong selection");

  }
  get_customers() {
    this.api.get_all_customer().subscribe((data) => {
      this.customers = data;
      console.log('cus', data);
    });

  }
  loadcus(e: any) {
    console.log('event', e);
    if (e == true) {
      this.get_customers();
    }
  }

  /*****************GET MODAL************************** */
  addCustomer() {
    this.displayModal = true;
    this._modal = 1;
  }
  hide() {
    this.displayModal = false;
  }

  get f() {
    return this.Loanform.controls;
  }
  /*****************Submit data************************* */

  onFormSubmit() {
    this.submitted=true;
    if (this.Loanform.invalid) {
        return;
    }
    else{
      this.api.createloan(this.Loanform.value).subscribe((data) => {
        this.get_res_loan(data);
      });
    }

  }
  get_res_loan(data: any) {
    console.log('response_data', data);
    if (data.success === true) {
      this.toastr.success(data.msg);
      this.Loanform.reset();
        this.router.navigate(['home/dash'])
    }
    else{
      this.toastr.error(data.msg);

    }
  }
  /*****************autofetch amounts************************** */

  blur_amt() {
    if ( this.Loanform.controls.Principle_Amount.value == null) {
      this.Loanform.controls.Principle_Amount.setValue(0);
    }
  }
  click_amt() {
    if ( this.Loanform.controls.Principle_Amount.value == 0) {
      this.Loanform.controls.Principle_Amount.setValue(null);
    }
  }
  blur_principleamt() {
    if ( this.Loanform.controls.Interest_Amount.value == null) {
      this.Loanform.controls.Interest_Amount.setValue(0);
    }
  }
  click_principleamt() {
    if ( this.Loanform.controls.Interest_Amount.value == 0) {
      this.Loanform.controls.Interest_Amount.setValue(null);
    }
  }
  blur_premiumamt() {
    if (this.Loanform.controls.Premium_Amount.value == null) {
      this.Loanform.controls.Premium_Amount.setValue(0);
    }
  }
  click_premiumamt() {
    if (this.Loanform.controls.Premium_Amount.value == 0) {
      this.Loanform.controls.Premium_Amount.setValue(null);
    }
  }
  changetotalamount() {
    if ( this.Loanform.controls.Principle_Amount.value > 0) {
      this.Loanform.controls.Total_Amount.setValue(
        ( this.Loanform.controls.Principle_Amount.value -0)
         + ( this.Loanform.controls.Interest_Amount.value - 0)
      );
    }
    this.changepremiumamount();
  }
  checktotalamount() {
    if (this.Loanform.controls.Premium_Amount.value > this.Loanform.controls.Total_Amount.value) {
      this.toastr.error("Premium Amount > Total Amount not allowed");
    }
  }
  changepremiumamount()
  {
    if(this.Loanform.controls.Tenure.value != null)
    {
      this.Loanform.controls.Premium_Amount.setValue((this.Loanform.controls.Total_Amount.value - 0) / (this.Loanform.controls.Tenure.value - 0));
      var amt=Math.round(this.Loanform.controls.Premium_Amount.value *100)/100;
      this.Loanform.controls.Premium_Amount.setValue(amt);
      console.log('tenure',this.Loanform.controls.Tenure.value);
      console.log('amtttt',this.Loanform.controls.Total_Amount.value);
    }

  }

}

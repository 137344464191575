import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { TableModule } from 'ngx-easy-table';
import { ExcelService } from './services/excel.service';
import { ServiceApiService } from './services/service-api.service';
import { AppService } from './services/app.service';

 import { AppRoutingModule } from './app-routing.module';
//import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { JobsheetComponent } from './pages/Order/New/jobsheet.component';
import { ViewjobComponent } from './pages/Order/View/viewjob.component';
import { EditJobsheetComponent } from './pages/Order/Edit/edit-jobsheet.component';
import { AddCustomerComponent } from './components/customer/add-customer/add-customer.component';
import { EditCustomerComponent } from './components/customer/edit-customer/edit-customer.component';
import { ReportsComponent } from './pages/Reports/reports.component';
import { DetailReportsComponent } from './pages/Reports/Detail/enquiry-reports.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home';

import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { FormsComponent } from './pages/forms/forms.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PaymentComponent } from './pages/Order/payment/payment.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,JobsheetComponent,AddCustomerComponent,EditCustomerComponent,
    FormsComponent,
    ViewjobComponent,ReportsComponent,DetailReportsComponent,
    HomeComponent,LoginComponent,
    EditJobsheetComponent,
    PaymentComponent,
  ],
  imports: [
    BrowserModule,
    NgxDaterangepickerMd.forRoot(),
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TableModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    NguiAutoCompleteModule,
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),

    ],
  providers: [ExcelService, ServiceApiService,AppService],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { ServiceApiService } from '../../../services/service-api.service';

@Component({
  selector: 'app-viewjob',
  templateUrl: './viewjob.component.html',
  styleUrls: ['./viewjob.component.scss']
})
export class ViewjobComponent implements OnInit , OnDestroy {
  public configuration: Config;
  public columns_loanlist: Columns[];

  public data_loanlist = [];

  ID:any;
  // LOAN_DATE:any;
  CUSTOMER_FIRST_NAME:any;
  CUS_ID: any;
  ADDRESS:any;
  MOB_NO:any;
  KYC_TYPE:any;
  KYC_NO:any;
  AMOUNT: any;
  isLoadingResults = true;
  isRateLimitReached = false;
  constructor(public api: ServiceApiService,private router:Router) { }

  ngOnDestroy() : void {
    this.api.edit_mode=false;
  }
  ngOnInit(): void {
    this.loan_list();
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.columns_loanlist = [

      { key: 'CUSTOMER_FIRST_NAME', title: 'CUSTOMER' },
      { key: 'ADDRESS', title: 'ADDRESS' },
      { key: 'MOB_NO', title: 'CONTACT NO' },
      { key: 'Total_Amount', title: 'TOTAL AMOUNT'},
      { key: 'loan_close_flag', title: 'Loan Status'},


    ];
  }
  onEvent_loanlist(event)
  {
    console.log('Event',event);
    if(event.event==='onClick')
    {
      this.api.edit_mode=true;
      this.ID = event.value.row.id;
      this.CUS_ID=event.value.row.cus_id;
      this.CUSTOMER_FIRST_NAME=event.value.row.CUSTOMER_FIRST_NAME;
      this.ADDRESS = event.value.row.ADDRESS;
      this.MOB_NO = event.value.row.MOB_NO;
      this.KYC_NO = event.value.row.KYC_NO;
      this.KYC_TYPE = event.value.row.KYC_TYPE;
    }
  }
  addjoborder()
  {
    this.router.navigate(['/jobsheet']);
  }
  loan_list() {
    this.isLoadingResults=true;
    this.api.get_all_loanlist().subscribe((data) => {
     console.log('response table',data);
      this.data_loanlist = data;
      this.isLoadingResults=false;
    });
  }
  loanlist_table(e: any) {
    console.log(e);

    if(e == true)
      {
        this.loan_list();
      }

  }
}
